import React, { memo, useState } from 'react';
import { useFormik } from 'formik';
import { useUpdateEffect } from 'react-use';
import { useDispatch, useSelector } from 'react-redux';
import {
  notificationActions,
  updateNotificationReserve,
  cancelNotificationReserve,
  fetchNotificationReserveFileCheck,
  reserveCountNotification,
} from '../../slices/notificationSlice';
import { globalActions } from '../../slices/globalSlice';
import { Modal } from 'antd';
import {
  createNumberOptions,
  handlerFormikFieldChange,
  getMomentTagertWeekOfMonthNum,
  dateFormat,
} from '../../utils/fnUtil';
import {
  condOptions,
  genderOptions2,
  repeatTypeOptions,
  sendTypeOptions,
  prefectures,
  monthOptions,
} from '../../constants/options';
import BaseFileUpload from '../../components/baseUpload/file';
import Icons from '../../constants/Icons';
import PrefecturesDualListBox from '../dualListBox/prefectures';
import ModalTitle from '../modalTitle';
import TcButton from '../tcButton';
import TcRadio from '../tcCheckbox/tcRadio';
import Yup from '../../utils/yupUtil';
import moment from 'moment';
import DualListBox from '../dualListBox';
import './style.scss';
import DatePickerWrapper from '../util/datePickerWrapper';
import TimePickerWrapper from '../util/timePickerWrapper';
import SelectBox from '../util/selectBox';
import TextBox from '../util/textBox';
import CustomNumberPicker from '../util/customNumberPicker';
import DateTimeRangePicker from '../util/dateTimeRangePicker';

const NotificationSettingModal = memo(() => {
  const dispatch = useDispatch();

  const [availableShops, setAvailableShops] = useState([]);

  const { accountInfo, accountBrandList, accountShopList } = useSelector(
    state => state.account
  );

  const { selectOptionList } = useSelector(state => state.name);

  const { add_user_data } = accountInfo;

  const {
    notificationSettingModalVisible,
    notificationReserveDetail,
    fileUploadStatus,
    scenarioType,
    notificationReserveCount,
  } = useSelector(state => state.notification);

  const [availablePrefectures, setAvailablePrefectures] = useState(prefectures);

  const formik = useFormik({
    initialValues: {
      document_id: '',
      send_type: '1',
      repeat_type: '0',
      send_date: '',
      send_time_hh: null,
      send_time_mm: null,
      repeat_send_time_hh: null,
      repeat_send_time_mm: null,
      repeat_period: null,
      timing: null,
      repeat_send_time: null,
      repeat_start_date: '',
      repeat_end_date: '',
      cond_flag: '0',
      from_age: null,
      to_age: null,
      gender: '0',
      birth_month: null,
      prefectures_list: [],
      shop_list: [],
      file_type: '0',
      upload_file: null,
      data_count: null,
      isCreate: true,
      scenario_type: null,
      scenario_day: null,
      scenario_day_type: null,
    },
    validateOnMount: true,
    validationSchema: Yup.lazy(({ repeat_type, send_type }) =>
      send_type === '2'
        ? Yup.object({
            upload_file_name: Yup.string()
              .nullable()
              .test(
                'upload_file_name',
                'ファイルを選択してください',
                (value, testContext) =>
                  testContext.parent.cond_flag !== '2' ||
                  !['0', '1'].includes(testContext.parent.file_type) ||
                  value
              ),
          })
        : repeat_type === '0'
        ? Yup.object({
            send_date: Yup.string().required(),
            send_time_hh: Yup.string().nullable().selected(),
            send_time_mm: Yup.string().nullable().selected(),
            upload_file_name: Yup.string()
              .nullable()
              .test(
                'upload_file_name',
                'ファイルを選択してください',
                (value, testContext) =>
                  testContext.parent.cond_flag !== '2' ||
                  !['0', '1'].includes(testContext.parent.file_type) ||
                  value
              ),
          })
        : Yup.object({
            repeat_start_date: Yup.string().required(),
            repeat_end_date: Yup.string().required(),
            repeat_send_time_hh: Yup.string().nullable().selected(),
            repeat_send_time_mm: Yup.string().nullable().selected(),
            repeat_period: Yup.string().nullable().selected(),
            timing: Yup.string()
              .nullable()
              .test(
                'timing_required',
                '必須',
                (_, context) =>
                  context.parent.repeat_type === '0' || context.parent.timing
              ),
            upload_file_name: Yup.string()
              .nullable()
              .test(
                'upload_file_name',
                'ファイルを選択してください',
                (value, testContext) =>
                  testContext.parent.cond_flag !== '2' ||
                  !['0', '1'].includes(testContext.parent.file_type) ||
                  value
              ),
          })
    ),
    onSubmit: values => {
      const {
        send_type,
        send_date,
        send_time_hh,
        send_time_mm,
        repeat_send_time_hh,
        repeat_send_time_mm,
        prefectures_list,
        repeat_type,
        repeat_start_date,
        repeat_period,
        repeat_end_date,
        timing,
        shop_list,
        isCreate,
        ...rest
      } = values;

      // 予約配信の場合、日時チェック
      if (send_type === '1') {
        // 配信日チェック
        if (repeat_type === '0') {
          const sendDate = moment(send_date)
            .set('h', send_time_hh)
            .set('m', send_time_mm);
          if (moment(sendDate).isBefore(moment())) {
            dispatch(
              globalActions.showErrorModal({
                title: 'エラー',
                message: '配信日に過去日を設定することはできません',
              })
            );
            return;
          }
        }

        // 開始日チェック
        if (repeat_type === '1') {
          const repeatStartDate = moment(repeat_start_date)
            .set('h', repeat_send_time_hh)
            .set('m', repeat_send_time_mm);
          if (moment(repeatStartDate).isBefore(moment())) {
            dispatch(
              globalActions.showErrorModal({
                title: 'エラー',
                message: '開始日に過去日を設定することはできません',
              })
            );
            return;
          }
        }
      }

      dispatch(
        updateNotificationReserve({
          ...rest,
          send_type,
          send_date: moment(send_date)?.format('YYYY/MM/DD'),
          repeat_type,
          repeat_period,
          repeat_start_date: dateFormat(repeat_start_date, 'YYYY/MM/DD', ''),
          repeat_end_date: dateFormat(repeat_end_date, 'YYYY/MM/DD', ''),
          timing: {
            day: repeat_period === 'day' ? timing : null,
            week: repeat_period === 'week' ? timing : null,
            month:
              repeat_period === 'month'
                ? {
                    month_day:
                      timing === 0 ? moment(repeat_start_date).date() : null,
                    month_week:
                      timing === 1
                        ? {
                            day_of_week: Math.ceil(
                              moment(repeat_start_date).date() / 7
                            ),
                            repeat_week:
                              moment(repeat_start_date).format('dddd'),
                          }
                        : null,
                  }
                : null,
          },
          send_time: `${send_time_hh ?? ''}${send_time_mm ?? ''}`,
          repeat_send_time: `${repeat_send_time_hh ?? ''}${
            repeat_send_time_mm ?? ''
          }`,
          prefectures_list: prefectures_list.map(p => p.value),
          shop_list: shop_list.map(s => s.shop_code),
          operator_code: accountInfo.staff_code,
          isCreate,
        })
      );
    },
  });

  useUpdateEffect(
    () =>
      notificationReserveDetail &&
      formik
        .setValues({
          ...notificationReserveDetail,
          prefectures_list: (
            notificationReserveDetail.prefectures_list ?? []
          ).map(v => ({
            value: v,
            label: prefectures?.find(p => p?.value === v)?.label,
          })),
          shop_list: (notificationReserveDetail.shop_list ?? []).map(s => ({
            shop_code: s,
            shop_name: accountShopList.find(v => v.value === s)?.label,
          })),
          gender: notificationReserveDetail.gender ?? '0',
        })
        .then(() => formik.validateForm()),
    [notificationReserveDetail]
  );

  useUpdateEffect(() => {
    if (!notificationSettingModalVisible) {
      formik.resetForm();
      formik.validateForm();
      setAvailablePrefectures(prefectures);
      setAvailableShops([]);
    }
  }, [notificationSettingModalVisible]);

  const {
    prefectures_list,
    send_type,
    repeat_type,
    cond_flag,
    shop_list,
    file_type,
    repeat_start_date,
    upload_file_name,
    repeat_period,
    isCreate,
  } = formik.values;

  const onBack = () => {
    dispatch(notificationActions.resetNotificationModal());
    formik.resetForm();
  };

  const reserveCount = () => {
    dispatch(reserveCountNotification({
      ...formik.values,
      prefectures_code: prefectures_list.map(p => p.value),
      favorite_shops_code: shop_list.map(p => p.value),
    }));
  };

  return (
    <Modal
      className="notification-modal tc-modal"
      centered
      footer={null}
      closable={false}
      width={1000}
      open={notificationSettingModalVisible}
      destroyOnClose={true}
      onCancel={onBack}
    >
      <div className="tc-modal-content">
        <ModalTitle title={'配信設定'} tip={'*は必須項目です'} />
        <div className="input-area">
          <span className="cus-input-line input-checkbox">
            <TcRadio
              options={sendTypeOptions}
              formik={formik}
              label={'配信区分'}
              width={180}
              fieldName={'send_type'}
              cancelable={false}
              isRadio={true}
            />
          </span>
          {send_type === '1' && (
            <>
              <span className="cus-input-line input-checkbox">
                <TcRadio
                  options={repeatTypeOptions}
                  formik={formik}
                  label={'リピート'}
                  width={190}
                  fieldName={'repeat_type'}
                  cancelable={false}
                  isRadio={true}
                />
              </span>
              {repeat_type === '0' && (
                <DatePickerWrapper
                  formik={formik}
                  fieldName="send_date"
                  preLabel="配信日"
                  maxWidth={280}
                  isRequired
                />
              )}
              {repeat_type === '1' && (
                <DatePickerWrapper
                  formik={formik}
                  fieldName="repeat_start_date"
                  preLabel="開始日"
                  maxWidth={280}
                  isRequired
                />
              )}
              <TimePickerWrapper
                preLabel="配信時間"
                formik={formik}
                hourFieldName={
                  repeat_type === '0' ? 'send_time_hh' : 'repeat_send_time_hh'
                }
                minuteFieldName={
                  repeat_type === '0' ? 'send_time_mm' : 'repeat_send_time_mm'
                }
                maxWidth={280}
                isRequired
              />
              <span className="cus-input-line input-repeat-type">
                <SelectBox
                  preLabel="繰り返し間隔"
                  formik={formik}
                  fieldName="repeat_period"
                  options={selectOptionList?.filter(s => s.field_name === '繰り返し間隔')?.[0]
                    ?.select_option ?? []}
                  placeholder={''}
                  maxWidth={280}
                  isRequired
                  extraOnChange={(_, value) => {
                    let timingValue = null;
                    if (value === 'day') {
                    } else if (value === 'week') {
                      repeat_start_date &&
                        (timingValue = moment(repeat_start_date).format('dd'));
                    } else {
                      repeat_start_date && (timingValue = 0);
                    }
                    handlerFormikFieldChange(formik, 'timing', timingValue);
                  }}
                  disabled={repeat_type === '0'}
                />
                <SelectBox
                  formik={formik}
                  fieldName={'timing'}
                  options={
                    repeat_period === 'day'
                      ? createNumberOptions(365, 1, '日')
                      : repeat_start_date
                      ? repeat_period === 'week'
                        ? [
                            {
                              label: `${moment(repeat_start_date).format(
                                'dddd'
                              )}`,
                              value: moment(repeat_start_date).format('dd'),
                            },
                          ]
                        : repeat_period === 'month'
                        ? [
                            {
                              label: `${moment(repeat_start_date).date()}日`,
                              value: 0,
                            },
                            {
                              label: `第${getMomentTagertWeekOfMonthNum(
                                repeat_start_date
                              )}週${moment(repeat_start_date).format('dddd')}`,
                              value: 1,
                            },
                          ]
                        : []
                      : []
                  }
                  placeholder={''}
                  width={repeat_period === 'day' ? 172 : 150}
                  disabled={repeat_type === '0'}
                  suffix={repeat_period === 'day' ? '毎' : ''}
                  flexEnd
                  style={{ marginLeft: 32 }}
                />
              </span>
              <DatePickerWrapper
                formik={formik}
                fieldName="repeat_end_date"
                preLabel="終了日"
                disabled={repeat_type === '0'}
                maxWidth={280}
                isRequired
              />
            </>
          )}
          <span className="cus-input-line input-checkbox">
            <TcRadio
              options={condOptions}
              formik={formik}
              label={'配信条件'}
              width={240}
              fieldName={'cond_flag'}
              cancelable={false}
              isRadio={true}
            />
          </span>
          {cond_flag === '1' ? (
            <>
              <span className="cus-input-line input-age">
                <CustomNumberPicker
                  preLabel="年齢"
                  formik={formik}
                  fieldName="from_age"
                  from={1}
                  to={120}
                  placeholder={'00'}
                  width={302}
                  suffix={'〜'}
                />
                <CustomNumberPicker
                  formik={formik}
                  fieldName="to_age"
                  from={1}
                  to={120}
                  width={150}
                  placeholder="00"
                  style={{ marginLeft: 10 }}
                />
              </span>
              <span className="cus-input-line input-checkbox">
                <TcRadio
                  options={genderOptions2}
                  formik={formik}
                  label={'性別'}
                  width={230}
                  fieldName={'gender'}
                  cancelable={false}
                  isRadio={true}
                />
              </span>
              <SelectBox
                preLabel="誕生月"
                formik={formik}
                fieldName="birth_month"
                options={monthOptions}
                placeholder={'00'}
                width={302}
                suffix={'月'}
              />
              <div className="check-area">
                <span className={'cus-pre-label'}>都道府県</span>
                <PrefecturesDualListBox
                  selectBoxId="notification_prefectures_select_box"
                  selectPrefectures={prefectures_list}
                  setSelectPrefectures={v =>
                    handlerFormikFieldChange(formik, 'prefectures_list', v)
                  }
                  availablePrefectures={availablePrefectures}
                  setAvailablePrefectures={setAvailablePrefectures}
                  disabled={false}
                />
              </div>
              <div className="check-area">
                <span className={'cus-pre-label'}>お気に入り店舗</span>
                <DualListBox
                  selectBoxId="shop_select_box"
                  brandList={accountBrandList}
                  shopList={accountShopList}
                  selectShops={shop_list}
                  setSelectShops={v =>
                    handlerFormikFieldChange(formik, 'shop_list', v)
                  }
                  availableShops={availableShops}
                  setAvailableShops={setAvailableShops}
                  disabled={false}
                />
              </div>
              {(add_user_data ?? []).map(a => {
                switch (a.filter_type) {
                  case '0':
                    return (
                      <TextBox
                        formik={formik}
                        preLabel={a.name}
                        fieldName={`add_user_data_params.${a.field_name}`}
                        width={'100%'}
                      />
                    );
                  case '1':
                  case '2':
                    return (
                      <SelectBox
                        formik={formik}
                        preLabel={a.name}
                        fieldName={`add_user_data_params.${a.field_name}`}
                        mode={a.filter_type === '2' ? 'multiple' : null}
                        options={(a.filter_list ?? []).map(l => ({
                          label: l.list_name,
                          value: l.list_code,
                        }))}
                      />
                    );
                  case '3':
                    return (
                      <span className="cus-input-line input-age">
                        <CustomNumberPicker
                          preLabel={a.name}
                          formik={formik}
                          fieldName={`add_user_data_params.${a.field_name}_start`}
                          placeholder={'0'}
                          width={302}
                          suffix={'〜'}
                          from={a.filter_range?.start ?? 0}
                          to={a.filter_range?.end ?? 0}
                        />
                        <CustomNumberPicker
                          formik={formik}
                          fieldName={`add_user_data_params.${a.field_name}_end`}
                          width={150}
                          placeholder="0"
                          style={{ marginLeft: 10 }}
                          from={a.filter_range?.start ?? 0}
                          to={a.filter_range?.end ?? 0}
                        />
                      </span>
                    );
                  case '4':
                    return (
                      <DateTimeRangePicker
                        formik={formik}
                        preLabel={a.name}
                        fromFieldName={`add_user_data_params.${a.field_name}_start`}
                        toFieldName={`add_user_data_params.${a.field_name}_end`}
                        maxWidth={150}
                        width={462}
                      />
                    );
                  default:
                    return null;
                }
              })}
              <div className="scenario-area">
                <SelectBox
                  preLabel="シナリオ"
                  formik={formik}
                  fieldName="scenario_type"
                  options={scenarioType}
                  placeholder={'選択してください'}
                  width={280}
                  extraOnChange={() =>
                    handlerFormikFieldChange(formik, 'scenario_day_type', null)
                  }
                />
                <TextBox
                  formik={formik}
                  fieldName="scenario_day"
                  placeholder="0"
                  width={172}
                  suffix={'日'}
                />
                <SelectBox
                  formik={formik}
                  fieldName="scenario_day_type"
                  options={selectOptionList
                    ?.filter(s => s.field_name === 'シナリオ設定')?.[0]
                    ?.select_option?.filter(option => {
                      // クーポン失効日
                      if (formik.values.scenario_type === 'CU00001') {
                        return option.value === '0' || option.value === '1';
                      }
                      // 最終起動日
                      if (formik.values.scenario_type === 'US00001') {
                        return option.value === '2' || option.value === '3';
                      }
                      // 誕生日
                      if (formik.values.scenario_type === 'US00002') {
                        return option.value === '0' || option.value === '2';
                      }
                      // ダウンロード日
                      if (formik.values.scenario_type === 'AN00001') {
                        return option.value === '2' || option.value === '3';
                      }
                      if (!formik.values.scenario_type) {
                        return false;
                      }
                      return option;
                    })}
                  placeholder={'選択してください'}
                  width={150}
                />
              </div>
            </>
          ) : cond_flag === '2' && (
            <div className="cus-input-line input-file">
              <span className={'cus-pre-label label-required'}>IDリスト</span>
              <div>
                <span className="input-file-label">ファイル区分</span>
                <div style={{ display: 'flex' }}>
                  <TcRadio
                    isRadio={true}
                    options={[{ label: '端末ID', value: '0' }]}
                    formik={formik}
                    width={80}
                    fieldName={'file_type'}
                    onChange={() => {
                      handlerFormikFieldChange(formik, 'upload_file', null);
                      handlerFormikFieldChange(
                        formik,
                        'upload_file_name',
                        null
                      );
                      dispatch(notificationActions.clearFileUploadStatus());
                      handlerFormikFieldChange(
                        formik,
                        'file_type',
                        '0'
                      );
                    }}
                  />
                  <TcRadio
                    isRadio={true}
                    options={[{ label: '会員番号', value: '1' }]}
                    formik={formik}
                    width={80}
                    fieldName={'file_type'}
                    onChange={() => {
                      handlerFormikFieldChange(formik, 'upload_file', null);
                      handlerFormikFieldChange(
                        formik,
                        'upload_file_name',
                        null
                      );
                      dispatch(notificationActions.clearFileUploadStatus());
                      handlerFormikFieldChange(
                        formik,
                        'file_type',
                        '1'
                      );
                    }}
                  />
                </div>
                <div
                  className="file-area"
                  style={{ display: 'flex', alignItems: 'center' }}
                >
                  <BaseFileUpload
                    onChange={v => {
                      handlerFormikFieldChange(formik, 'upload_file', v.file);
                      handlerFormikFieldChange(
                        formik,
                        'upload_file_name',
                        v.file.name
                      );
                      dispatch(
                        fetchNotificationReserveFileCheck({
                          operator_code: accountInfo.staff_code,
                          target_list_type: file_type,
                          upload_file: v.file,
                        })
                      );
                    }}
                    maxLine={90000}
                  >
                    <TcButton
                      text="ファイル選択"
                      theme="white"
                      style={{ marginLeft: 0 }}
                      icon={<Icons.IconClip />}
                    />
                  </BaseFileUpload>
                  <div
                    style={{
                      marginLeft: 10,
                      maxWidth: 300,
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    }}
                  >
                    ファイル名：{upload_file_name ? upload_file_name : '-'}
                  </div>
                  {fileUploadStatus && (
                    <div style={{ marginLeft: 20 }}>
                      ファイル取込成功件数：{fileUploadStatus?.success_count}{' '}
                      / {fileUploadStatus?.all_count}
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
          <div className="reserve-count-area">
            <div className="reserve-count-label">想定配信人数</div>
            <div className="reserve-count-value">{`${notificationReserveCount?.toLocaleString()}　人`}</div>
            <TcButton text="集計" theme="white" onClick={reserveCount} />
          </div>
          <div className="button-container">
            <TcButton text="戻る" theme="white" onClick={onBack} />
            <TcButton
              text={'更新'}
              disabled={
                !formik.isValid ||
                (['0', '1'].includes(file_type) && fileUploadStatus?.success_count === 0)
              }
              onClick={formik.handleSubmit}
            />
            {!isCreate && (
              <a
                className="cancel-link"
                onClick={() =>
                  dispatch(
                    cancelNotificationReserve({
                      reserve_id: notificationReserveDetail.reserve_id,
                    })
                  )
                }
              >
                配信予約をキャンセルする
              </a>
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
});

export default NotificationSettingModal;
