import React, { useEffect, useRef, useState } from 'react';
import { Select } from 'antd';
import { RightOutlined, LeftOutlined } from '@ant-design/icons';
import Button from '../tcButton';
import Icons from '../../constants/Icons';
import './style.scss';

/*  */

const DualListBox = ({
  brandList,
  shopList,
  selectShops,
  setSelectShops,
  availableShops,
  setAvailableShops,
  disabled = false,
  onReflect,
}) => {
  const containerRef = useRef(null);
  const [selectedAddShops, setSelectedAddShops] = useState([]);
  const [selectedRemoveShops, setSelectedRemoveShops] = useState([]);

  const changeSelectBrandEvent = b => {
    setAvailableShops(
      shopList.filter(
        shop =>
          b === shop?.brand?.brand_code &&
          !selectShops?.find(s => shop?.shop_code === s?.shop_code)
      )
    );
  };

  const add = () => {
    setSelectShops(
      selectShops
        .concat(selectedAddShops)
        .sort((a, b) => a.shop_code - b.shop_code)
    );
    const notSelected = availableShops.filter(
      shop => !selectedAddShops.includes(shop)
    );
    setAvailableShops(notSelected);
    setSelectedAddShops([]);
    setSelectedRemoveShops([]);
  };

  const remove = () => {
    setAvailableShops(
      availableShops
        .concat(selectedRemoveShops)
        .sort((a, b) => a.shop_code - b.shop_code)
    );
    const notSelected = selectShops.filter(
      shop => !selectedRemoveShops.includes(shop)
    );
    setSelectShops(notSelected);
    setSelectedRemoveShops([]);
    setSelectedAddShops([]);
  };

  const handleSelectAddShop = shop => {
    if (selectedAddShops.includes(shop)) {
      setSelectedAddShops(selectedAddShops.filter(item => item !== shop));
    } else {
      setSelectedAddShops([...selectedAddShops, shop]);
    }
  };
  const handleSelectRemoveShop = shop => {
    if (selectedRemoveShops.includes(shop)) {
      setSelectedRemoveShops(selectedRemoveShops.filter(item => item !== shop));
    } else {
      setSelectedRemoveShops([...selectedRemoveShops, shop]);
    }
  };

  useEffect(() => {
    const handleClickOutside = event => {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target)
      ) {
        setSelectedAddShops([]);
        setSelectedRemoveShops([]);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="dualListBox-container">
      <div className="tc-dualListBox" ref={containerRef}>
        <div className="select-container" tabIndex={0}>
          {selectShops?.filter(shop => shop?.shop_code).map((shop, i) => (
            <div
              key={'availableShops' + i}
              value={shop.shop_code}
              className={`dropdown-option ${
                selectedRemoveShops.includes(shop) ? 'selected' : ''
              }`}
              onClick={() => handleSelectRemoveShop(shop)}
            >
              <div className={"text-ellipsis"}>
                {shop?.shop_name}
              </div>
            </div>
          ))}
        </div>
        <div className="actions-container">
          <Button
            disabled={disabled}
            text={<LeftOutlined />}
            style={{ width: 150 }}
            theme="white"
            onClick={add}
          />
          <Button
            disabled={disabled}
            text={<RightOutlined />}
            style={{ width: 150 }}
            theme="white"
            onClick={remove}
          />
        </div>
        <div>
          {brandList?.filter(b => !selectShops?.some(shop => shop?.value === b?.value)) && (
            <Select
              name={'brand_select'}
              className="brand_select"
              onChange={changeSelectBrandEvent}
              placeholder="ブランドを選択してください"
              showSearch={true}
              filterOption={(input, option) =>
                `${option.children}`
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
              suffixIcon={
                disabled ? (
                  <Icons.IconChevronDownGy className="ant-select-suffix select-icon" />
                ) : (
                  <Icons.IconChevronDown className="ant-select-suffix select-icon" />
                )
              }
            >
              {brandList?.map(e => (
                <Select.Option key={e.brand_code} value={e.brand_code}>
                  {e.brand_name}
                </Select.Option>
              ))}
            </Select>
          )}
          <div
            className="select-container"
            style={brandList ? { height: 140, marginTop: 5 } : { height: 175 }}
            tabIndex={0}
          >
            {availableShops.map((shop, i) => (
              <div
                key={'availableShops' + i}
                value={shop?.shop_code}
                className={`dropdown-option ${
                  selectedAddShops.includes(shop) ? 'selected' : ''
                }`}
                onClick={() => handleSelectAddShop(shop)}
              >
                <div className={"text-ellipsis"}>
                  {shop?.shop_name}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      {onReflect && (
        <Button
          disabled={disabled}
          text={'反映'}
          style={{ width: 226, margin: 0, marginTop: 10 }}
          theme="white"
          onClick={onReflect}
        />
      )}
    </div>
  );
};

export default DualListBox;
