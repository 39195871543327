import { Modal, Image } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { couponActions } from '../../slices/couponSlice';
import ModalTitle from '../modalTitle';
import Button from '../../components/tcButton';
import {
  prefectures,
  deliveryTargetOptions,
  genderOptions2,
  couponTypeOptions,
} from '../../constants/options';
import { toSlateState } from '../richTextEditor/utils/serializer';
import RichTextEditor from '../richTextEditor';
import './style.scss';

/* クーポン参照モーダル */

const CouponReferenceModal = () => {
  const dispatch = useDispatch();

  const { accountInfo, accountShopList: shopList } = useSelector(
    state => state.account
  );

  const { add_user_data, new_contents_flag } = accountInfo;

  const { referenceModalVisible, referenceCoupon } = useSelector(
    state => state.coupon
  );

  const { selectOptionList } = useSelector(state => state.name);

  const initialValues = {
    coupon_code: null,
    coupon_type: '0',
    coupon_name: null,
    coupon_detail: null,
    rich_coupon_detail: null,
    image: null,
    use_date_from: null,
    use_hour_from: null,
    use_minute_from: null,
    use_date_to: null,
    use_hour_to: null,
    use_minute_to: null,
    from_send_date: null,
    to_send_date: null,
    valid_days_flag: '0',
    valid_days: null,
    barcode: null,
    barcode2: null,
    usage_count_flag: '0',
    usage_count: '0',
    template: null,
    fixed_phrase: null,
    delivery_target: '01',
    delivery_conditions_flag: '0',
    from_age: null,
    to_age: null,
    gender: '0',
    birth_month: null,
    prefectures_code: [],
    favorite_shops_code: [],
    target_list_type: '0',
    upload_file: null,
    upload_file_name: null,
    notice_flag: '0',
    day_count: null,
  };

  const {
    coupon_type,
    coupon_name,
    coupon_detail,
    rich_coupon_detail,
    image,
    valid_days,
    from_usage_period,
    to_usage_period,
    from_send_date,
    to_send_date,
    barcode,
    barcode2,
    usage_count_flag,
    usage_count,
    fixed_phrase,
    delivery_target,
    delivery_conditions_flag,
    from_age,
    to_age,
    gender,
    birth_month,
    prefectures_code,
    favorite_shops_code,
    notice_flag,
    target_list_type,
    upload_file_name,
    add_user_data_params,
    day_count,
  } = referenceCoupon || initialValues;

  const onBack = () => {
    dispatch(couponActions.closeReferenceModal());
  };

  const dateToString = date => moment(date)?.format('YYYY/M/D HH:mm') || '';

  return (
    <Modal
      className={'coupon-modal tc-modal'}
      open={referenceModalVisible}
      centered
      footer={null}
      closable={false}
      maskClosable={true}
      width={900}
      onCancel={onBack}
      destroyOnClose={true}
    >
      <div className="tc-modal-content">
        <ModalTitle
          title={'クーポン参照'}
          tip={''}
          rightElement={
            <div style={{ marginRight: 20 }}>
              {`${
                couponTypeOptions.find(v => v.value === coupon_type)?.label ??
                couponTypeOptions[0].label
              }クーポン`}
            </div>
          }
        />
        <div className="input-area">
          <div className="text-area">
            <span className={'pre-label'}>クーポン名</span>
            <span className="font-sm">{coupon_name}</span>
          </div>
          <div
            className="text-area"
            style={new_contents_flag !== 0 ? { height: 'unset' } : {}}
          >
            <span className={'pre-label editor-detail-title'}>
              クーポン説明
            </span>
            {new_contents_flag !== 0 ? (
              <div style={{ flexGrow: 1, width: '100%' }}>
                <span className="editor-detail">
                  <RichTextEditor
                    readOnly={true}
                    editorState={toSlateState(rich_coupon_detail)}
                  />
                </span>
              </div>
            ) : (
              <div className="font-sm" style={{ whiteSpace: 'pre-wrap' }}>
                {coupon_detail}
              </div>
            )}
          </div>
          <div className="image-area">
            <span className={'pre-label'}>画像</span>
            <div className="image-select">
              <Image src={image} width={150} height={200} preview={false} />
            </div>
          </div>
          {coupon_type !== '0' ? (
            <></>
          ) : (
            <>
              <div className="date-area">
                <span className={'pre-label'}>利用期間</span>
                <span className="font-sm">
                  {dateToString(from_usage_period)}　〜　
                  {dateToString(to_usage_period)}
                </span>
              </div>
              <div className="date-area">
                <span className={'pre-label'}>発行期間</span>
                <span className="font-sm">
                  {dateToString(from_send_date)}　〜　
                  {dateToString(to_send_date)}
                </span>
              </div>
            </>
          )}
          {coupon_type !== '0' && (
            <div className="check-area">
              <span
                className={'pre-label'}
                style={{ whiteSpace: 'pre-wrap' }}
              >{`クーポン${'\n'}有効日数指定`}</span>
              <span className="font-sm">
                クーポン発行からn日の有効日数を指定する {valid_days}日
              </span>
            </div>
          )}
          <div className="text-area">
            <label>
              {selectOptionList?.filter(s => s.field_name === '回収バーコード')?.[0]
              ?.select_option?.find(v => v.value === "0")?.label}
            </label>
            <div className="font-sm" style={{ whiteSpace: 'pre-wrap' }}>
              {barcode}
            </div>
          </div>
          <div className="text-area">
            <label>
              {selectOptionList?.filter(s => s.field_name === '回収バーコード')?.[0]
              ?.select_option?.find(v => v.value === "1")?.label}
            </label>
            <div className="font-sm" style={{ whiteSpace: 'pre-wrap' }}>
              {barcode2}
            </div>
          </div>
          <div className="check-area">
            <span className={'pre-label'}>クーポン利用回数</span>
            <span className="font-sm">
              {usage_count_flag === '0' ? '無制限' : `${usage_count}回`}
            </span>
          </div>
          <div className="text-area">
            <label>定型文</label>
            <div className="font-sm" style={{ whiteSpace: 'pre-wrap' }}>
              {fixed_phrase}
            </div>
          </div>
          {coupon_type !== '0' ? (
            <></>
          ) : (
            <>
              <div className="check-area">
                <span className={'pre-label'}>配信対象</span>
                <span className="font-sm">
                  {deliveryTargetOptions[delivery_target]}
                </span>
              </div>
              {delivery_target === '03' ? (
                <div className="coupon-modal-coupon-day-count-area">
                  <label>クーポン受取期間</label>
                  <span>{`${day_count ?? "-"}　日`}</span>
                </div>
              ) : delivery_target === '04' ? (
                <>
                  <div className="check-area">
                    <span
                      className={'pre-label'}
                      style={{ whiteSpace: 'pre-wrap' }}
                    >{`IDリスト${'\n'}アップロード`}</span>
                    <div className="font-sm">
                      ファイル区分：
                      {target_list_type === '0' ? '端末ID' : '会員番号'}
                    </div>
                  </div>
                  <div className="file-area">
                    <span className={'pre-label'}></span>
                    <span className="font-sm">
                      ファイル名：{upload_file_name ? upload_file_name : '-'}
                    </span>
                  </div>
                </>
              ) : (
                <div className="check-area">
                  <span className={'pre-label'}>配信条件</span>
                  <span className="font-sm">
                    {delivery_conditions_flag === '0' ? '条件なし' : '条件あり'}
                  </span>
                </div>
              )}
              {delivery_target !== '04' && delivery_conditions_flag === '1' ? (
                <>
                  <div className="check-area">
                    <span className={'pre-label'}>年齢</span>
                    <span className="font-sm">
                      {!from_age && !to_age
                        ? '指定なし'
                        : `${from_age || '指定なし'} ~ ${to_age || '指定なし'}`}
                    </span>
                  </div>
                  <div className="check-area">
                    <span className={'pre-label'}>性別</span>
                    <span className="font-sm">
                      {genderOptions2[gender]?.label}
                    </span>
                  </div>
                  <div className="check-area">
                    <span className={'pre-label'}>誕生月</span>
                    <span className="font-sm">
                      {birth_month ? `${birth_month} 月` : '指定なし'}
                    </span>
                  </div>
                  <div className="check-area">
                    <span className={'pre-label'}>都道府県</span>
                    <div className="font-sm" style={{ whiteSpace: 'pre-wrap' }}>
                      {prefectures_code
                        ?.map(
                          e => prefectures?.find(p => p?.value === e)?.label
                        )
                        ?.join(', ') || '指定なし'}
                    </div>
                  </div>
                  <div className="check-area">
                    <span className={'pre-label'}>お気に入り店舗</span>
                    <div className="font-sm" style={{ whiteSpace: 'pre-wrap' }}>
                      {favorite_shops_code
                        ?.map(
                          e =>
                            shopList?.filter(s => e === s?.shop_code)?.[0]
                              ?.shop_name || e
                        )
                        ?.join(', ') || '指定なし'}
                    </div>
                  </div>
                  {(add_user_data ?? []).map(a => (
                    <div className="check-area">
                      <span className={'pre-label'}>{a.name}</span>
                      <div
                        className="font-sm"
                        style={{ whiteSpace: 'pre-wrap' }}
                      >
                        {add_user_data_params?.[`${a.field_name}_display`] ||
                          '指定なし'}
                      </div>
                    </div>
                  ))}
                </>
              ) : (
                <></>
              )}
              <div className="check-area">
                <span className={'pre-label'}>プッシュ配信通知</span>
                <span className="font-sm">
                  {notice_flag === '0' ? 'しない' : 'する'}
                </span>
              </div>
            </>
          )}
          <div className="button-container">
            <Button text="閉じる" theme="white" onClick={onBack} />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default CouponReferenceModal;
