import './style.scss';
import { RightOutlined, LeftOutlined } from '@ant-design/icons';
import Button from '../tcButton';
import React, { useEffect, useRef, useState } from 'react';

/*  */

const PrefecturesDualListBox = ({
  selectBoxId,
  selectPrefectures,
  setSelectPrefectures,
  availablePrefectures,
  setAvailablePrefectures,
  disabled = false,
}) => {
  const containerRef = useRef(null);

  const [selectedAddPrefectures, setSelectedAddPrefectures] = useState([]);
  const [selectedRemovePrefectures, setSelectedRemovePrefectures] = useState(
    []
  );

  const add = () => {
    setSelectPrefectures(
      selectPrefectures
        .concat(selectedAddPrefectures)
        .sort((a, b) => a.value - b.value)
    );
    const notSelected = availablePrefectures.filter(
      prefecture => !selectedAddPrefectures.includes(prefecture)
    );
    setAvailablePrefectures(notSelected);
    setSelectedAddPrefectures([]);
    setSelectedRemovePrefectures([]);
  };

  const remove = () => {
    setAvailablePrefectures(
      availablePrefectures
          ?.filter(p => !selectPrefectures?.some(pref => pref?.value === p?.value))
          ?.concat(selectedRemovePrefectures)
          ?.sort((a, b) => a.value - b.value)
    );
    const notSelected = selectPrefectures.filter(
      prefecture => !selectedRemovePrefectures.includes(prefecture)
    );
    setSelectPrefectures(notSelected);
    setSelectedRemovePrefectures([]);
    setSelectedAddPrefectures([]);
  };

  const handleSelectAddPrefecture = prefecture => {
    if (selectedAddPrefectures.includes(prefecture)) {
      setSelectedAddPrefectures(
        selectedAddPrefectures.filter(item => item !== prefecture)
      );
    } else {
      setSelectedAddPrefectures([...selectedAddPrefectures, prefecture]);
    }
  };
  const handleSelectRemovePrefecture = prefecture => {
    if (selectedRemovePrefectures.includes(prefecture)) {
      setSelectedRemovePrefectures(
        selectedRemovePrefectures.filter(item => item !== prefecture)
      );
    } else {
      setSelectedRemovePrefectures([...selectedRemovePrefectures, prefecture]);
    }
  };

  useEffect(() => {
    const handleClickOutside = event => {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target)
      ) {
        setSelectedAddPrefectures([]);
        setSelectedRemovePrefectures([]);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="tc-dualListBox" ref={containerRef}>
      <div className="select-container" tabIndex={0}>
        {selectPrefectures.map((prefecture, i) => (
          <div
            key={'selectedPrefecture' + i}
            value={prefecture.value}
            className={`dropdown-option ${
              selectedRemovePrefectures.includes(prefecture) ? 'selected' : ''
            }`}
            onClick={() => handleSelectRemovePrefecture(prefecture)}
          >
            <div className={"text-ellipsis"}>
              {prefecture.label}
            </div>
          </div>
        ))}
      </div>
      <div className="actions-container">
        <Button
          disabled={disabled}
          text={<LeftOutlined />}
          style={{ width: 150 }}
          theme="white"
          onClick={add}
        />
        <Button
          disabled={disabled}
          text={<RightOutlined />}
          style={{ width: 150 }}
          theme="white"
          onClick={remove}
        />
      </div>
      <div className="select-container" tabIndex={0}>
        {availablePrefectures?.filter(p => !selectPrefectures?.some(pref => pref?.value === p?.value))?.map((prefecture, i) => (
          <div
            key={'availablePrefecture' + i}
            value={prefecture.value}
            className={`dropdown-option ${
              selectedAddPrefectures.includes(prefecture) ? 'selected' : ''
            }`}
            onClick={() => handleSelectAddPrefecture(prefecture)}
          >
            <div className={"text-ellipsis"}>
              {prefecture.label}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PrefecturesDualListBox;
