import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useUpdateEffect } from 'react-use';
import { useFormik } from 'formik';
import { notificationColumns } from '../../constants/columns';
import {
  fetchNotificationList,
  notificationActions,
  fetchNotificationDetail,
  fetchNotificationReserveDetail,
  fetchNotificationReference,
  deleteNotification, fetchScenarioType, fetchNotificationCopy,
} from '../../slices/notificationSlice';
import {
  canUpdateSendStatus,
  canUpdateSendStatusOnlyNotificationPage
} from '../../constants/options';
import { globalActions } from '../../slices/globalSlice';
import { dateFormat, handlerFormikFieldChange } from '../../utils/fnUtil';
import NotificationModal from '../../components/notificationModal';
import NotificationSettingModal from '../../components/notificationModal/notificationSettingModal';
import TcButton from '../../components/tcButton';
import PageTitle from '../../components/pageTitle';
import TableTitle from '../../components/tableTitle';
import TcCheckbox from '../../components/tcCheckbox';
import ColumnTitle from '../../components/columnTitle';
import TcTable from '../../components/tcTable';
import Icons from '../../constants/Icons';
import './style.scss';
import DocumentReferenceModal from '../../components/notificationModal/reference';
import InputContainer from '../../components/inputContainer';
import TextBox from '../../components/util/textBox';
import DateTimeRangePicker from '../../components/util/dateTimeRangePicker';

const Notification = () => {
  const dispatch = useDispatch();
  const parent = useRef(null);

  const { searchParams, notificationList, total } = useSelector(
    state => state.notification
  );

  const formik = useFormik({
    initialValues: searchParams,
    enableReinitialize: true,

    onSubmit: values => {
      const { from_create_at, to_create_at, ...rest } = values;
      const params = {
        ...rest,
        from_create_at: dateFormat(from_create_at, 'YYYY/MM/DD'),
        to_create_at: dateFormat(to_create_at, 'YYYY/MM/DD'),
      };

      dispatch(notificationActions.saveSearchParams(params));
    },
  });

  const isUpdatable = (sendStatus) => {
    // それ以外のsendStatusは編集不可
    return canUpdateSendStatusOnlyNotificationPage.includes(sendStatus);
  }

  useUpdateEffect(
    () => dispatch(fetchNotificationList(searchParams)),
    [searchParams]
  );

  useEffect(() => {
    dispatch(fetchNotificationList(null));
    return () => dispatch(notificationActions.reset());
  }, [dispatch]);

  const columns = [
    ...notificationColumns.map(col => ({
      title: (
        <ColumnTitle
          title={col.title}
          formik={formik}
          sortField={col.sortField}
          onClick={sort =>
            dispatch(notificationActions.saveSearchParams({ sort }))
          }
        />
      ),
      dataIndex: col.sortField,
      render: col.render,
    })),
    {
      title: '編集',
      align: 'center',
      width: '80px',
      render: current =>
        current.deleted_flg === '1' ? (
          <Icons.IconEditGy className="table-cell-icon" />
        ) : isUpdatable(current.send_status) ? (
          <Icons.IconEdit
            className="table-cell-icon tc-action-icon"
            onClick={() =>
              dispatch(
                fetchNotificationDetail({
                  document_id: current.document_id,
                })
              )
            }
          />
        ) : (
          <Icons.IconSearch
            className="table-cell-icon tc-action-icon"
            onClick={() =>
              dispatch(
                fetchNotificationReference({ document_id: current.document_id })
              )
            }
          />
        ),
    },
    {
      title: '複製',
      align: 'center',
      width: '80px',
      render: current => (
        <Icons.IconCopy
          className="tc-action-icon"
          height={'20px'}
          width={'20px'}
          onClick={() => dispatch(fetchNotificationCopy(current))}
        />
      ),
    },
    {
      title: '設定',
      align: 'center',
      width: '80px',
      render: current =>
        current.deleted_flg === '0' &&
        (!current.send_status ||
          (current.send_status &&
            canUpdateSendStatus.includes(current.send_status))) &&
        current.document_category === '0' ? (
          <Icons.IconSettings
            className="table-cell-icon tc-action-icon"
            onClick={() => {
              dispatch(
                fetchNotificationReserveDetail({
                  document_id: current.document_id,
                })
              );
              dispatch(fetchScenarioType())
            }
            }
          />
        ) : (
          <Icons.IconSettingsGy className="table-cell-icon" />
        ),
    },
    {
      title: '削除',
      align: 'center',
      width: '80px',
      render: current =>
        current.deleted_flg === '1' ? (
          <Icons.IconSearch
            className="table-cell-icon tc-action-icon"
            onClick={() =>
              dispatch(
                fetchNotificationReference({ document_id: current.document_id })
              )
            }
          />
        ) : (
          <Icons.IconTrash
            className="table-cell-icon tc-action-icon"
            onClick={() =>
              dispatch(
                globalActions.showDeleteModal({
                  message: `${current.document_id} ${current.title}\nを削除します\nよろしいですか?`,
                  tip: '※同一のCDは使用不可となります',
                  deleteAction: deleteNotification({
                    document_id: current.document_id,
                  }),
                })
              )
            }
          />
        ),
    },
  ];

  return (
    <div className="content-body management-page" ref={parent}>
      <PageTitle
        title="新規登録・配信設定"
        icon={<Icons.IconBell className="page-title-icon" />}
      />
      <div className="search-form">
        <InputContainer parent={parent} max={1098}>
          <TextBox formik={formik} label="タイトル" fieldName="title" />
          <TextBox formik={formik} label="メッセージ" fieldName="message" />
          <DateTimeRangePicker
            formik={formik}
            label="登録日"
            fromFieldName="from_create_at"
            toFieldName="to_create_at"
          />
        </InputContainer>
        <div className="search-btn-container">
          <TcButton text={'検索'} onClick={formik.handleSubmit} />
          <TcCheckbox
            className="deleted-checkbox"
            label={'削除済み'}
            checked={formik.values.show_delete_flag === 1}
            onChange={() =>
              handlerFormikFieldChange(
                formik,
                'show_delete_flag',
                formik.values.show_delete_flag === 1 ? 0 : 1
              )
            }
          />
        </div>
      </div>
      <TableTitle
        searchCount={total}
        onClickAdd={() =>
          dispatch(notificationActions.setNotificationModalVisible(true))
        }
      />
      <TcTable
        columns={columns}
        dataSource={notificationList}
        offset={searchParams.offset}
        total={total}
        onChange={offset => {
          dispatch(notificationActions.saveOffset(offset));
          formik.setFieldValue('offset', offset);
        }}
      />
      <NotificationModal />
      <NotificationSettingModal />
      <DocumentReferenceModal />
    </div>
  );
};

export default Notification;
