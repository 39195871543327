import { memo, useState } from 'react';
import { useFormik } from 'formik';
import { useUpdateEffect } from 'react-use';
import { useDispatch, useSelector } from 'react-redux';
import {
  geopushActions,
  updateGeopush,
} from '../../slices/geopushInfoSlice';
import {
  shopManagementActions,
} from '../../slices/shopManagementSlice';
import { globalActions } from '../../slices/globalSlice';
import { Modal, Tooltip } from 'antd';
import {
  handlerFormikFieldChange,
} from '../../utils/fnUtil';
import {
  sendTypeOptions,
} from '../../constants/options';
import { geopushInfoColumns } from '../../constants/columns';
import Icons from '../../constants/Icons';
import ModalTitle from '../modalTitle';
import ColumnTitle from '../../components/columnTitle';
import TcTable from '../../components/tcTable';
import TcButton from '../tcButton';
import Yup from '../../utils/yupUtil';
import moment from 'moment';
import DualListBox from '../dualListBox';
import './style.scss';
import DatePickerWrapper from '../util/datePickerWrapper';
import TimePickerWrapper from '../util/timePickerWrapper';
import TextBox from '../util/textBox';
import GoogleMapSelect from '../googleMap';

const GeopushModal = memo(() => {
  const dispatch = useDispatch();

  const [availableShops, setAvailableShops] = useState([]);

  const { accountInfo, accountBrandList, accountShopList } = useSelector(
    state => state.account
  );

  const {
    searchParams,
    geopushList,
    total,
    geopushDetail,
    geopushModalVisible
  } = useSelector(state => state.geopushInfo);

  const { add_user_data } = accountInfo;

  const formik = useFormik({
    initialValues: {
      document_id: '',
      send_type: '1',
      send_plan_date_from: null,
      send_plan_time_from_hh: null,
      send_plan_time_from_mm: null,
      send_plan_date_to: null,
      send_plan_time_to_hh: null,
      send_plan_time_to_mm: null,
      shop_list: [],
      data_count: null,
      location_info_list: [],
      isCreate: false,
      location_name: 'test',
      latitude: 0,
      longitude: 0,
      radius: 300,
    },
    validateOnMount: true,
    validationSchema: Yup.lazy(({ send_type }) =>
      send_type === '1' ?
        Yup.object({
          send_plan_date_from: Yup.string().required(),
          send_plan_time_from_hh: Yup.string().nullable().selected(),
          send_plan_time_from_mm: Yup.string().nullable().selected(),
          send_plan_date_to: Yup.string().required(),
          send_plan_time_to_hh: Yup.string().nullable().selected(),
          send_plan_time_to_mm: Yup.string().nullable().selected(),
          // location_info_list: Yup.array()
          //   .test(
          //     'location_info_list',
          //     '編集を完了してください',
          //     list => {
          //       console.log(`list = ${JSON.stringify(list)}`)
          //       if (list == null || list.length === 0)
          //         return true;

          //       return !list?.find(v => v.edit_flag);
          //     }
          //   ),
        })
        : Yup.object({})
    ),
    onSubmit: values => {
      const {
        document_id,
        send_type,
        send_plan_date_from,
        send_plan_time_from_hh,
        send_plan_time_from_mm,
        send_plan_date_to,
        send_plan_time_to_hh,
        send_plan_time_to_mm,
        location_info_list,
        isCreate,
        ...rest
      } = values;

      // 予約配信の場合、日時チェック
      if (send_type === '1') {
        // 配信日チェック
        const sendDateFrom = moment(send_plan_date_from)
          .set('h', send_plan_time_from_hh)
          .set('m', send_plan_time_from_mm);
        const sendDateTo = moment(send_plan_date_to)
          .set('h', send_plan_time_to_hh)
          .set('m', send_plan_time_to_mm);
        if (moment(sendDateFrom).isBefore(moment()) || moment(sendDateTo).isBefore(moment())) {
          dispatch(
            globalActions.showErrorModal({
              title: 'エラー',
              message: '配信日に過去日を設定することはできません',
            })
          );
          return;
        }
      }

      // 位置情報入力チェック
      if (location_info_list != null) {
        for (const locationInfo of location_info_list) {
          if (locationInfo.edit_flag) {
            dispatch(
              globalActions.showErrorModal({
                title: 'エラー',
                message: '位置情報の編集を完了してください',
              })
            );
            return;
          }
        }
      }

      delete rest['shop_list'];

      dispatch(
        updateGeopush({
          ...rest,
          document_id,
          send_type,
          send_date: moment()?.format('YYYY/MM/DD'),
          send_time: '0000',
          send_plan_date_from: `${moment(send_plan_date_from)?.format('YYYY/MM/DD')} ${send_plan_time_from_hh}:${send_plan_time_from_mm}`,
          send_plan_date_to: `${moment(send_plan_date_to)?.format('YYYY/MM/DD')} ${send_plan_time_to_hh}:${send_plan_time_to_mm}`,
          geopush_flag: true,
          location_info_list: geopushList,
          operator_code: accountInfo.staff_code,
          isCreate: geopushDetail.reserve_id == null ? true : false,
        })
      );
    },
  });

  useUpdateEffect(
    () =>
      geopushDetail &&
      formik
        .setValues({
          ...geopushDetail,
          shop_list: (geopushDetail.shop_list ?? []).map(s => ({
            shop_code: s,
            shop_name: accountShopList.find(v => v.value === s)?.label,
          })),
        })
        .then(() => formik.validateForm()),
    [geopushDetail]
  );

  useUpdateEffect(() => {
    handlerFormikFieldChange(formik, 'location_info_list', geopushList)
    dispatch(shopManagementActions.setRadius(0));
    dispatch(shopManagementActions.setLatitude(null));
    dispatch(shopManagementActions.setLongitude(null));
  }, [geopushList]);

  useUpdateEffect(() => {
    if (!geopushModalVisible) {
      formik.resetForm();

      formik.validateForm();
      setAvailableShops([]);
    }
  }, [geopushModalVisible]);

  // 入力枠初期値
  let initialInput = {
    shop_code: '',
    location_name: '',
    latitude: null,
    longitude: null,
    radius: null,
    edit_flag: true,
  }

  const {
    send_type,
    shop_list,
  } = formik.values;

  // 店舗リスト反映ボタン押下イベント
  const onClickShopListApply = () => {
    let addShops = [];
    for (const shop of formik.values.shop_list) {

      const locationInfoList = formik.values.location_info_list;
      if (locationInfoList && locationInfoList.find(obj => obj.shop_code === shop.shop_code) !== undefined) {
        continue;
      }

      console.log(`shop = ${JSON.stringify(shop)}`)
      const editFlag = (shop.hasOwnProperty('location') && shop.location == null)
        || (shop.hasOwnProperty('location') && shop.location != null && shop.location.includes(null))
        || (shop.hasOwnProperty('radius') && shop.radius == null) ? true : false;

      addShops.push(
        {
          shop_code: shop.shop_code,
          location_name: shop.shop_name,
          latitude: shop.location == null ? null : shop.location[0],
          longitude: shop.location == null ? null : shop.location[1],
          radius: shop.radius,
          edit_flag: editFlag
        }
      );
    }

    // console.log(`location_info_list = ${JSON.stringify(formik.values.location_info_list)}`)
    // console.log(`geopushList = ${JSON.stringify(geopushList)}`)
    // console.log(`addShops = ${JSON.stringify(addShops)}`)

    dispatch(geopushActions.setLocationInfoList({
      location_info_list: [
        ...geopushList,
        ...addShops
      ]
    }));
    // handlerFormikFieldChange(formik, 'location_info_list', [...geopushList, ...addShops]);
  };

  // 位置情報反映ボタン押下イベント
  const onClickLocationInfoApply = index => {
    let columns = [];
    let message = "";
    const rowData = formik.values.location_info_list[index];

    // 必須入力チェック
    for (const column of geopushInfoColumns) {
      const value = rowData[column.sortField];
      if (value == null || value === '')
        columns.push(column.title);

      message = columns.join(', ');
    }
    if (message !== '') {
      dispatch(
        globalActions.showErrorModal({
          title: 'エラー',
          message: message + 'を入力してください',
        })
      );
      return;
    }

    // 数値入力チェック
    for (const column of geopushInfoColumns) {
      if (column.sortField === 'shop_code' || column.sortField === 'location_name')
        continue;

      const value = rowData[column.sortField];
      if (isNaN(value))
        columns.push(column.title);

      message = columns.join(', ');
    }
    if (message !== '') {
      dispatch(
        globalActions.showErrorModal({
          title: 'エラー',
          message: message + 'は数値を入力してください',
        })
      );
      return;
    }

    // 緯度・経度チェック
    const latitude = Number(rowData['latitude']);
    if (latitude < 0 || latitude > 90) {
      dispatch(
        globalActions.showErrorModal({
          title: 'エラー',
          message: '緯度は0～90の数値を入力してください',
        })
      );
      return;
    }

    const longitude = Number(rowData[`longitude`])
    if (longitude < 0 || longitude > 180) {
      dispatch(
        globalActions.showErrorModal({
          title: 'エラー',
          message: '経度は0～180の数値を入力してください',
        })
      );
      return;
    }

    // 半径範囲チェック
    const radius = Number(rowData['radius'])
    if (radius < 300 || radius > 10000) {
      dispatch(
        globalActions.showErrorModal({
          title: 'エラー',
          message: '半径は300～10000の数値を入力してください',
        })
      );
      return;
    }

    dispatch(
      geopushActions.setEditFlag(
        {
          index: index,
          list: formik.values.location_info_list
        }
      )
    );
    // handlerFormikFieldChange(formik, `location_info_list[${index}].edit_flag`, false);
  };

  // 位置情報削除ボタン押下イベント
  const onClickLocationInfoDelete = (current, index) => {
    dispatch(
      globalActions.showDeleteModal({
        message: current.location_name !== '' ? `${current.location_name} \nを削除します\nよろしいですか?` : `削除します\nよろしいですか?`,
        deleteAction: geopushActions.deleteLocationInfo({ index: index })
      })
    );
  };

  const onClickRadius = current => {
    dispatch(shopManagementActions.setRadius(current.radius));
    dispatch(shopManagementActions.setLatitude(parseFloat(current.latitude)));
    dispatch(shopManagementActions.setLongitude(parseFloat(current.longitude)));
  };

  const onBack = () => {
    dispatch(geopushActions.resetGeopushModal());
    formik.resetForm();
  };

  // console.log(`formik.values = ${JSON.stringify(formik.values)}`);
  // console.log(`geopushDetail = ${JSON.stringify(geopushDetail)}`);
  // console.log(`formik.values.location_info_list = ${JSON.stringify(formik.values.location_info_list)}`);

  const columns = [
    ...geopushInfoColumns.map(col => ({
      title: (
        <ColumnTitle
          title={col.title}
          formik={formik}
          onClick={sort => dispatch(geopushActions.saveSearchParams({ sort }))}
        />
      ),
      dataIndex: col.sortField,
      width: col.width,
      render: (value, record, index) => {
        return record.edit_flag ?
          <TextBox
            formik={formik}
            placeholder={col.placeHolder}
            fieldName={`location_info_list[${index}].${col.sortField}`}
            suffix={col.sortField === 'radius' ? 'm' : ''}
            width={'none'}
          />
          : col.sortField === 'radius' ? Number(value).toLocaleString() + 'm' : value;
      }
    })),
    {
      title: '中央に表示',
      align: 'center',
      width: '140px',
      onCell: current => {
        return { colSpan: current.edit_flag ? 2 : 1 }
      },
      render: (current, value, index) => {

        return current.edit_flag ? (
          <TcButton
            text={'反映'}
            width="400"
            onClick={() => onClickLocationInfoApply(index)}
          />
        ) : (
          <TcButton
            text="中央に表示"
            theme="white"
            style={{
              width: 100,
            }}
            icon={<Icons.IconCheckIn className="btn-icon" />}
            onClick={() => onClickRadius(current)}
          />
        )
      }
    },
    {
      title: '編集',
      align: 'center',
      width: '80px',
      onCell: current => {
        return { colSpan: current.edit_flag ? 0 : 1 }
      },
      render: (current, value, index) =>
        current.deleted_flg === '1' ? (
          <Icons.IconEditGy className="table-cell-icon" />
        ) : (
          <Icons.IconEdit
            className="table-cell-icon tc-action-icon"
            onClick={() => {
              dispatch(
                geopushActions.setEditFlag(
                  {
                    index: index,
                    list: formik.values.location_info_list
                  }
                )
              )
            }
            }
          />
        ),
    },
    {
      title: '削除',
      align: 'center',
      width: '80px',
      render: (current, value, index) =>
        <Icons.IconTrash
          className="table-cell-icon tc-action-icon"
          onClick={() => {
            onClickLocationInfoDelete(current, index);
          }}
        />
    }
  ];

  return (
    <Modal
      className="notification-modal tc-modal"
      centered
      footer={null}
      closable={false}
      width={1000}
      open={geopushModalVisible}
      destroyOnClose={true}
      onCancel={onBack}
    >
      <div className="tc-modal-content">
        <ModalTitle title={'配信設定'} tip={'*は必須項目です'} />
        <div className="input-area">
          <span className="cus-input-line">
            <DatePickerWrapper
              formik={formik}
              fieldName="send_plan_date_from"
              preLabel="配信期間"
              maxWidth={280}
              isRequired
              suffix={' '}
            />
            <TimePickerWrapper
              formik={formik}
              hourFieldName='send_plan_time_from_hh'
              minuteFieldName='send_plan_time_from_mm'
              maxWidth={280}
              isRequired
              suffix={'～'}
            />
            <DatePickerWrapper
              formik={formik}
              fieldName="send_plan_date_to"
              maxWidth={150}
              isRequired
              suffix={' '}
            />
            <TimePickerWrapper
              formik={formik}
              hourFieldName='send_plan_time_to_hh'
              minuteFieldName='send_plan_time_to_mm'
              maxWidth={280}
              isRequired
            />
          </span>
          <>
            <div className="check-area">
              <span className={'cus-pre-label'}>配信店舗</span>
              <DualListBox
                selectBoxId="shop_select_box"
                brandList={accountBrandList}
                shopList={accountShopList?.filter(s => !formik?.values?.location_info_list?.some(l => l?.shop_code === s?.shop_code))}
                selectShops={shop_list}
                setSelectShops={v => handlerFormikFieldChange(formik, 'shop_list', v)}
                availableShops={availableShops}
                setAvailableShops={setAvailableShops}
                disabled={false}
              />
            </div>
            <TcButton
              text={'反映'}
              style={{ marginTop: -15, marginLeft: 130, width: 225 }}
              onClick={() => onClickShopListApply()}
              disabled={formik.values.shop_list.length === 0}
            />
          </>
          <GoogleMapSelect
            formik={formik}
            fieldName1={'latitude'}
            fieldName2={'longitude'}
            fieldNameRadius={'radius'}
            geopush={true}
          />
          <TcTable
            columns={columns}
            dataSource={geopushList}
            offset={searchParams.offset}
            total={total}
            onChange={offset => {
              dispatch(geopushActions.saveOffset(offset));
              formik.setFieldValue('offset', offset);
            }}
            pagenationFlag={false}
          />
          <div className="add-btn-area">
            <Tooltip
              title="入力枠追加"
              color="#C4A6A9"
              overlayClassName="add-tooltip"
            >
              <Icons.IconPlusWh
                className="plus-icon"
                onClick={() => {
                  dispatch(geopushActions.setLocationInfoList({
                    location_info_list: [
                      ...geopushList,
                      initialInput
                    ]
                  }));
                  handlerFormikFieldChange(formik, 'location_info_list', [...geopushList, initialInput]);
                }
                }
              />
            </Tooltip>
          </div>
          <div className="button-container">
            <TcButton text="戻る" theme="white" onClick={onBack} />
            <TcButton
              text={'登録'}
              disabled={!formik.isValid}
              onClick={formik.handleSubmit}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
});

export default GeopushModal;
